
@media print {
header, .ant-layout-sider, .no-print, .ant-message, .ant-layout-footer {
    display: none;
}
@page { size: auto !important;
}
.ant-table thead.ant-table-thead {display: table-header-group;}
.ant-table .ant-table-content .ant-table-body table tr, .ant-table .ant-table-content .ant-table-body table td {
    font-size: 11px !important;
}
.ant-table .ant-table-content .ant-table-body table th, .ant-table .ant-table-content .ant-table-body table td {
    padding: 1px 2px;
}
.ant-table { table-layout: auto;
}
.utils__content {
    padding: 0px 8px !important;
}
.ant-table-body { margin-bottom: 80px;
}
html {
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}
.ant-table .ant-table-body .driver-name-cell {
    width: 110px !important;
}
.ant-table .ant-table-body .company-name-cell {
    width: 80px !important;
}
}
.print-tab .ant-table-bordered .ant-table-thead > tr > th, body .print-tab .ant-table-wrapper tr td {
  border: 1px solid #e8e8e8 !important;
}
.print-tab .ant-table-bordered .ant-table-thead > tr > .border-left-thick, .print-tab .ant-table-bordered .ant-table-tbody > tr > .border-left-thick {
  border-left: 2.5px solid #e8e8e8 !important;
}
